import { useEffect, useState } from "react";

import './App.css';
import * as Postmonger from 'postmonger';

// For testing
// window.payload = {
//   arguments: {
//     execute: {
//       inArguments: [{
//         rutCliente: '{{Contact.Attribute.Generar_Cupones_Push_Cumpleanos.RutCliente}}',
//         isRutClienteValid: true
//       }]
//     }
//   }
// }

const INPUT_CLASS_NAME = `
  rounded border-[1px] border-gray-300 w-full
  outline-0 px-4 h-10 transition duration-200
  invalid:border-red-600
  invalid:focus:border-red-600 invalid:focus:shadow-red-700/50
  focus:border-sky-500 focus:shadow-md focus:shadow-sky-600/50 
`;

function App() {
  const [connection, ] = useState(new Postmonger.Session());
  const [eventDefinitionKey, setEventDefinitionKey] = useState('');
  const [isRendered, setIsRendered] = useState(false);
  const [rutCliente, setRutCliente] = useState({value:'',error:''});
  const [idCupon, setIdCupon] = useState({value:'',error:''});
  const [waitEventDefinitionKey, setWaitEventDefinitionKey] = useState({value:'',error:''});

  console.log(eventDefinitionKey);

  useEffect(() => {
    if(isRendered) return;
    
    const initialize = (data) => {
      console.log('*** initialize ***');
  
      try {
        if(!data) throw new Error('Custom activity sin config.json');

        //Debido a que en las funciones que llama MC para hacer funcionar el Custom Activity
        //No conocen los States de React, lo necesario para esas funciones se guardan en 'window'
        window.payload = {...data};

        const inArguments = data?.arguments?.execute?.inArguments ?? [];
        const hasInArguments = inArguments.length > 0;
  
        //Setear config ya guardada en el front
        if(hasInArguments) {
          setRutCliente({value:inArguments[0].rutCliente, error: inArguments[0].isRutClienteValid ? 'hidden' : ''});
          setIdCupon({value:inArguments[0].idCupon, error: inArguments[0].isIdCuponValid ? 'hidden' : ''});
          setWaitEventDefinitionKey({value:inArguments[0].waitEventDefinitionKey, error: inArguments[0].isWaitEventDefinitionKeyValid ? 'hidden' : ''});
        }

        console.log('*payload:',data)
      } catch (error) {
        console.log('*** initialize Error***');
        console.log(error);
      }
    }

    // For testing
    // initialize(window.payload)

    const validateData = () => {
      const payload = {...window.payload};
      const inArguments = payload.arguments.execute.inArguments[0];
      console.log(inArguments)
      // Agregar la demás validaciones aquí con &&
      const valid = inArguments?.isIdCuponValid && inArguments?.isWaitEventDefinitionKeyValid && inArguments?.isRutClienteValid; 

      return { valid, payload }
    }

    const onClickedNext = () => {
      console.log('*** onClickedNext ***');
      try {
        const { valid, payload } = validateData();
        console.info('Is form valid:');
        console.info(valid);
        if(!valid) return;

        payload.metaData.isConfigured = true;
        console.log(payload.metaData)
        console.log(payload.arguments.execute.inArguments[0])
        connection.trigger('updateActivity', payload);
      } catch (error) {
        console.log('*** onClickedNext Error***');
        console.log(error);
      }
    }
  
    const requestedTriggerEventDefinition = (eventDefinitionModel) => {
      console.log('*** requestedTriggerEventDefinition ***');
      try {
        console.log(eventDefinitionModel);
        setEventDefinitionKey(eventDefinitionModel.eventDefinitionKey);
      } catch (error) {
        console.log('*** requestedTriggerEventDefinition Error***');
        console.log(error);
      }
    }

    connection.on('initActivity', initialize);
    
    connection.on('clickedNext', onClickedNext);

    connection.on('requestedTriggerEventDefinition', requestedTriggerEventDefinition);
    connection.trigger('ready');

    setIsRendered(true);
  }, [connection, isRendered]);

  const handleChangeRutCliente = ({target}) => {
    const newPayload = {...window.payload};
    const value = target.value;
    const error = target.checkValidity() ? 'hidden' : '';
    setRutCliente({value, error, disabled: false});
    newPayload.arguments.execute.inArguments[0].rutCliente = value.replace(' ','').trim();
    newPayload.arguments.execute.inArguments[0].isRutClienteValid = target.checkValidity();
    window.payload = {...newPayload};
  }

  const handleChangeCupon = ({target}) => {
    const newPayload = {...window.payload};
    const value = target.value;
    const error = target.checkValidity() ? 'hidden' : '';
    setIdCupon({value, error});
    newPayload.arguments.execute.inArguments[0].idCupon = value.replace(' ','').trim();
    newPayload.arguments.execute.inArguments[0].isIdCuponValid = target.checkValidity();
    window.payload = {...newPayload};
  }

  const handleChangeWaitEventDefinitionKey = ({target}) => {
    const newPayload = {...window.payload};
    const value = target.value;
    const error = target.checkValidity() ? 'hidden' : '';
    setWaitEventDefinitionKey({value, error});
    newPayload.arguments.execute.inArguments[0].waitEventDefinitionKey = value.replace(' ','').trim();
    newPayload.arguments.execute.inArguments[0].isWaitEventDefinitionKeyValid = target.checkValidity();
    window.payload = {...newPayload};
  }

  const handleToggleEdit = () => {
    const el = document.getElementById('rutCliente')
    el.toggleAttribute('disabled')
    el.focus()

  }

  return (
    <div className="bg-white p-4 w-full">
      <form className="flex flex-col gap-6">
        <div>
          <label htmlFor="rutCliente" className="flex flex-col gap-1">
            <span className="text-sm text-gray-600"><span className="text-red-500">*</span> Rut Cliente:</span>
            <div className="relative w-full">
              <input
                id="rutCliente"
                type="text"
                name="rutCliente"
                onChange={handleChangeRutCliente}
                pattern="\{\{Contact.Attribute(\.{1}[\w]{1,}){2}\}\}"
                value={rutCliente?.value}
                disabled
                className={INPUT_CLASS_NAME}
              />
              <button type="button" onClick={handleToggleEdit} class="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-green-700 rounded-r-sm border border-green-700 hover:bg-green-800 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                  <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                    <path d="M12.687 14.408a3.01 3.01 0 0 1-1.533.821l-3.566.713a3 3 0 0 1-3.53-3.53l.713-3.566a3.01 3.01 0 0 1 .821-1.533L10.905 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V11.1l-3.313 3.308Zm5.53-9.065.546-.546a2.518 2.518 0 0 0 0-3.56 2.576 2.576 0 0 0-3.559 0l-.547.547 3.56 3.56Z"/>
                    <path d="M13.243 3.2 7.359 9.081a.5.5 0 0 0-.136.256L6.51 12.9a.5.5 0 0 0 .59.59l3.566-.713a.5.5 0 0 0 .255-.136L16.8 6.757 13.243 3.2Z"/>
                  </svg>
                  <span class="sr-only">Editar</span>
                </button>
            </div>
          </label>
          <div className={`${rutCliente?.error} text-sm text-red-600 transition-all`}>Ingrese una referencia válida (Ej: &#123;&#123;Contact.Attribute.Maestro_Clientes_ContactId.rut&#125;&#125;)</div>
        </div>
        <div className="relative">
          <label htmlFor="idCupon" className="flex flex-col gap-1">
            <span className="text-sm text-gray-600"><span className="text-red-500">*</span> Id del cupón:</span>
            <input
              id="idCupon"
              name="idCupon"
              type="text"
              onChange={handleChangeCupon}
              value={idCupon?.value}
              maxLength={40}
              pattern="\w{40,40}"
              required
              className={INPUT_CLASS_NAME}
            />
          </label>
          <div className={`${idCupon?.error} text-sm text-red-600 transition-all`}>Ingrese un cupón válido</div>
        </div>
        <div className="relative">
          <label htmlFor="waitEventDefinitionKey" className="flex flex-col gap-1">
            <span className="text-sm text-gray-600"><span className="text-red-500">*</span> Event Definition Key (Wait Until Event):</span>
            <input
              id="waitEventDefinitionKey"
              name="waitEventDefinitionKey"
              type="text"
              onChange={handleChangeWaitEventDefinitionKey}
              value={waitEventDefinitionKey?.value}
              pattern="APIEvent-[\w\-]{36,}"
              required
              className={INPUT_CLASS_NAME}
            />
          </label>
          <div className={`${waitEventDefinitionKey?.error} text-sm text-red-600 transition-all`}>Ingrese un APIEvent válido (Ej: APIEvent-134748ac-f4df-a85c-d3b6-b870899c87b3)</div>
        </div>
      </form>
    </div>
  );
}

export default App;